<template>
  <div id="top-header">
    <a-row style="width: 100%">
      <a-col :span="7">
        <a-row>
          <a-col :span="6" @click="click('sy')" style="cursor: pointer">
            <dv-border-box-12 class="link-card"><span>首页</span></dv-border-box-12>
          </a-col>
          <a-col :span="6" @click="click('sb')" style="cursor: pointer">
            <dv-border-box-12 class="link-card">上报登记</dv-border-box-12>
          </a-col>
          <a-col :span="6" @click="click('qygl')" style="cursor: pointer">
            <dv-border-box-12 class="link-card">清运管理</dv-border-box-12>
          </a-col>
          <a-col :span="6" @click="click('qytj')" style="cursor: pointer">
            <dv-border-box-12 class="link-card">清运统计</dv-border-box-12>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="10">
        <div class="top-title">
          <dv-decoration-5 class="header-center-decoration"/>
          <span class="center-title">中宁县农村厕所改造调度运营数据大屏</span>
        </div>
      </a-col>
      <a-col :span="7">
        <a-row>
          <a-col :span="6" @click="click('clgl')" style="cursor: pointer">
            <dv-border-box-12 class="link-card">车辆管理</dv-border-box-12>
          </a-col>
          <a-col :span="6" @click="click('clpz')" style="cursor: pointer">
            <dv-border-box-12 class="link-card">车辆配置</dv-border-box-12>
          </a-col>
          <a-col :span="6" @click="click('jwdgl')" style="cursor: pointer">
            <dv-border-box-12 class="link-card">集污点管理</dv-border-box-12>
          </a-col>
          <a-col :span="6" style="cursor: pointer" @click="click('ddmb')">
            <dv-border-box-12 class="link-card">GIS地图</dv-border-box-12>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
  export default {
    name: 'TopHeader',
    data () {
      return {
        cardColor: ['#3f96a5'],
        backgroundColor: '#071531'
      }
    },
    methods: {
      click (val) {
        if (val === 'sy') {
          this.$router.push('/user/workplace')
        } else if (val === 'sb') {
          this.$router.push('/reportrecord/list')
        } else if (val === 'qygl') {
          this.$router.push('/loop/list')
        } else if (val === 'qytj') {
          this.$router.push('/statistics/loop/day')
        } else if (val === 'clgl') {
          this.$router.push('/baseinfo/car')
        } else if (val === 'clpz') {
          this.$router.push('/baseinfo/config')
        } else if (val === 'jwdgl') {
          this.$router.push('/baseinfo/gatherspot')
        } else if (val === 'ddmb') {
          this.$router.push('/dispatch/panel/zn')
        }

      }
    }
  }
</script>

<style lang="less">
  .top-title {
    position: absolute;
    width: 100%;
    top: 44px;
    .center-title {
      text-align: center;
      left: 150px;
      font-size: 34px;
      color: white;
      position: absolute;
      top: -16px;
      font-weight: bold;
    }
  }

  .header-center-decoration {
    width: 100%;
    height: 60px;
    margin: 30px auto 0 auto;
  }

  .link-card {
    box-shadow: none;
    margin: 40px 20px 20px 20px;
    text-align: center;
    line-height: 50px;
    width: 100px;
    height: 50px;
  }
</style>